import { AuthApi, UserApi } from 'services/api';
import { useState, useEffect } from 'react';
import Image from 'next/image';
import { useUser } from 'contexts/UserContext.tsx';
import Cookies from 'universal-cookie';
import { useErrorTracker } from 'utils/use-error-tracker';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel } from '@/components/ui/form.tsx';
import { Button } from '@/components/ui/button.tsx';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { Input } from '@/components/ui/input.tsx';
import Link from 'next/link';
import { ChevronLeft, Lock, Mail } from 'react-feather';
import { Drawer, DrawerTrigger, DrawerHeader, DrawerContent, DrawerClose } from '@/components/ui/drawer';
import { PasswordInput } from '@/components/ui/passwordInput';
import { LoadingSpinner } from '@/components/ui/spinner';
import { useRouter } from 'next/router';
import { getDefaultRedirectPage } from 'utils/getDefaultRedirectPage';

export function LoginForm({ customRedirect, mobile = false }) {
  const router = useRouter();
  const errorTracker = useErrorTracker();
  const {
    query: { tenant },
  } = router;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutateUser: muHandler } = useUser();
  useEffect(() => {
    const cookies = new Cookies();
    cookies.set('loginTo', tenant ? '/properties' : '/dashboard/bookings', {
      path: '/',
    });
  }, [tenant]);
  const [loading, setLoading] = useState(false);

  const loginFormSchema = z.object({
    email: z.string().email({
      message: 'Please enter a valid email address',
    }),
    password: z.string().min(8, {
      message: 'Password must be at least 8 characters',
    }),
  });
  const loginForm = useForm({
    resolver: zodResolver(loginFormSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const {
    formState: { errors },
    handleSubmit,
    setError,
  } = loginForm;

  const onSubmit = async values => {
    setLoading(true);

    const handleRouteChangeComplete = () => {
      setLoading(false);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };

    try {
      const { email, password } = values;
      await AuthApi.login({ email, password });
      const user = await UserApi.getCurrentUser();
      muHandler(user);
      router.events.on('routeChangeComplete', handleRouteChangeComplete);

      if (customRedirect) {
        customRedirect(user);
      } else {
        router.push(getDefaultRedirectPage(user.data));
      }
    } catch (error) {
      setError('email', {
        message: "That email and password combination doesn't match our records.",
      });
      setError('password', {
        message: "That email and password combination doesn't match our records.",
      });
      errorTracker.error('Login / Get current user error');
      setLoading(false);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    }
  };

  if (mobile) {
    return (
      <div className="flex flex-col items-center mt-10 text-white-200">
        <Drawer direction="right">
          <DrawerTrigger>
            <Button className="text-black bg-accent-400 cursor-pointer hover:bg-accent-200 w-80 mb-10">Log In</Button>
          </DrawerTrigger>
          <DrawerContent className="fixed inset-0 bg-white-50 z-50 flex flex-col mt-0 px-2">
            <DrawerHeader className="px-4 py-2 flex flex-col">
              <DrawerClose className="mb-8 mt-5">
                <ChevronLeft className="text-gray-400" />
              </DrawerClose>
              <Image src="/static/images/leni-logo-black.svg" height={40} width={200} alt="RealSage logo" />
              <span className="text-gray-500 mt-4">
                <span className="text-black font-bold">Log in</span> to your account
              </span>
            </DrawerHeader>
            <div className="flex-1 p-8">
              <Form {...loginForm}>
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
                  <FormField
                    className=""
                    control={loginForm.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem className="text-left">
                        <FormLabel className="text-xs text-gray-500">Email</FormLabel>
                        <FormControl
                          className={`focus:outline-none active:outline-none ${errors?.email && 'border-error-500'}`}>
                          <Input
                            icon={<Mail className="h-[16px] w-[16px] text-gray-400" />}
                            placeholder="john.doe@gmail.com"
                            {...field}
                            error={errors?.email}
                            aria-invalid={errors?.email ? 'true' : 'false'}
                            className="focus:outline-none active:outline-none"
                            type="email"
                          />
                        </FormControl>
                        {errors?.email && (
                          <FormDescription className="text-error-500 text-xs">*{errors?.email.message}</FormDescription>
                        )}
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={loginForm.control}
                    name="password"
                    render={({ field }) => (
                      <FormItem className="text-left">
                        <FormLabel className="text-xs text-gray-500">Password</FormLabel>
                        <FormControl
                          className={`focus:outline-none active:outline-none ${
                            errors?.password && 'border-error-500'
                          }`}>
                          <PasswordInput
                            icon={<Lock className="h-[16px] w-[16px] text-gray-400" />}
                            placeholder="••••••••"
                            {...field}
                            error={errors?.password}
                            aria-invalid={errors?.password ? 'true' : 'false'}
                            className={`focus:outline-none active:outline-none ${
                              errors?.password &&
                              'border-error-500 focus-within:border-error-200 focus-within:ring-error-100'
                            }`}
                          />
                        </FormControl>
                        <FormDescription className={`text-xs ${errors?.password ? 'text-error-500' : ''}`}>
                          {errors?.password
                            ? `*${errors?.password.message}`
                            : 'Password must be a minimum of 8 characters.'}
                        </FormDescription>
                      </FormItem>
                    )}
                  />
                  <div className="flex flex-col items-center">
                    <Link href="/forgot-password">
                      <span className="text-primary-700 cursor-pointer hover:text-primary-400 transition-all mt-4">
                        Forgot Password?
                      </span>
                    </Link>
                    <Button
                      className="text-black bg-accent-400 cursor-pointer hover:bg-accent-200 mt-5 w-full"
                      size="sm">
                      {!loading ? (
                        'Log in'
                      ) : (
                        <div className="flex items-center justify-center">
                          <LoadingSpinner className="mr-3" />
                          <span className="flex items-center text-center">Loading...</span>
                        </div>
                      )}
                    </Button>
                  </div>
                </form>
              </Form>
            </div>
          </DrawerContent>
        </Drawer>
        <span className>Don&apos;t have an account with us?</span>
        <span className>
          Request a{' '}
          <Link href="https://realsage.com/request-demo">
            <span className="text-primary-300 underline cursor-pointer">Product Demo</span>
          </Link>
        </span>
      </div>
    );
  }
  return (
    <div className="bg-white-50 px-12 py-16 w-[500px] flex flex-col rounded-2xl ">
      <div className="pb-5">
        <h2 className="display-lg text-black">Welcome</h2>
        <span className="text-xl">Log in to your account</span>
      </div>
      <Form {...loginForm}>
        <form onSubmit={loginForm.handleSubmit(onSubmit)} className="space-y-8">
          <FormField
            className=""
            control={loginForm.control}
            name="email"
            render={({ field }) => (
              <FormItem className="text-left">
                <FormLabel className="text-xs text-gray-500">Email</FormLabel>
                <FormControl
                  className={`focus:outline-none active:outline-none ${errors?.email && 'border-error-500'}`}>
                  <Input
                    icon={<Mail className="h-[16px] w-[16px] text-gray-400" />}
                    placeholder="john.doe@gmail.com"
                    {...field}
                    error={errors?.email}
                    aria-invalid={errors?.email ? 'true' : 'false'}
                    className="focus:outline-none active:outline-none"
                    type="email"
                  />
                </FormControl>
                {errors?.email && (
                  <FormDescription className="text-xs text-error-500">*{errors?.email.message}</FormDescription>
                )}
              </FormItem>
            )}
          />
          <FormField
            control={loginForm.control}
            name="password"
            render={({ field }) => (
              <FormItem className="text-left">
                <FormLabel className="text-xs text-gray-500">Password</FormLabel>
                <FormControl
                  className={`focus:outline-none active:outline-none ${errors?.password && 'border-error-500'}`}>
                  <PasswordInput
                    icon={<Lock className="h-[16px] w-[16px] text-gray-400" />}
                    placeholder="••••••••"
                    {...field}
                    error={errors?.password}
                    aria-invalid={errors?.password ? 'true' : 'false'}
                    className={`focus:outline-none active:outline-none ${
                      errors?.password && 'border-error-500 focus-within:border-error-200 focus-within:ring-error-100'
                    }`}
                  />
                </FormControl>
                <FormDescription className={`text-xs ${errors?.password ? 'text-error-500' : ''}`}>
                  {errors?.password ? `*${errors?.password.message}` : 'Password must be a minimum of 8 characters.'}
                </FormDescription>
              </FormItem>
            )}
          />
          <div className="flex flex-col">
            <Link href="/forgot-password">
              <span className="text-primary-700 cursor-pointer hover:text-primary-400 transition-all">
                Forgot Password?
              </span>
            </Link>
            <Button className="text-black bg-accent-400 cursor-pointer hover:bg-accent-200 my-6" size="sm">
              {!loading ? (
                'Log in'
              ) : (
                <div className="flex items-center justify-center">
                  <LoadingSpinner className="mr-3" />
                  <span>Loading...</span>
                </div>
              )}
            </Button>
          </div>
        </form>
      </Form>
      <span className="text-gray-700">
        Don&apos;t have an account? Request a{' '}
        <Link href="https://realsage.com/request-demo/">
          <span className="text-primary-600 underline cursor-pointer"> Product Demo</span>
        </Link>
      </span>
    </div>
  );
}

export default LoginForm;
