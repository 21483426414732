export const getDefaultRedirectPage = (user, redirectTo = false) => {
  // set default redirect page to the first element within user views
  // if this happens to be price, redirect to /get-rent-estimate
  // otherwise, if it is not dashboard, redirect to /dashboard/{first_element_in_user_views}
  // default: redirect to /dashboard
  if (user && user.userType !== 'landlord') {
    return '/properties';
  }
  if (user && user.userType === 'landlord') {
    if (!user.views) return '/dashboard/bookings';
    if (user.views.includes('bookings')) return '/dashboard/bookings';
    if (user.views[0] === 'dashboard') return '/dashboard/analytics?page=unitTurnData';
    return `/dashboard/${user.views[0]}`;
  }
  return redirectTo;
};
