import { useState, useEffect, useRef } from 'react';
import {
  Alert,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  ButtonToolbar,
  Button,
  FlexboxGrid,
  Schema,
  CheckPicker,
  AutoComplete,
  InputNumber,
} from 'rsuite';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Link } from 'utils/with-i18next';
import PhoneInput from 'react-phone-input-2';
import PasswordInput from 'components/PasswordInput';
import { OrganisationApi } from 'services/api/organisation';
import { useErrorTracker } from 'utils/use-error-tracker.js';

const StyledSection = styled('section')`
  font-size: 21px;
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 45px;
`;

function FormComponent({
  signup,
  login,
  setPassword,
  forgotPassword,
  titleText,
  buttonText,
  belowButtonText,
  belowButtonLink,
  handleSubmit,
  isLoading,
  onChangeFormValue,
  formValue,
  isPopup,
  setformAction,
  userType,
  showLink = true,
  setEmail,
}) {
  const errorTracker = useErrorTracker();
  const [organisationNameOptions, setOrganisationNameOptions] = useState([]);
  let formRef = useRef(null);

  useEffect(() => {
    const loadOrganisations = async () => {
      const organisations = await OrganisationApi.getAllOrganisationNames().catch(err => {
        errorTracker.error('Get all organisation names error');
        Alert.error('Something went wrong while fetching organisations.', 5000);
        console.error(err);
      });

      setOrganisationNameOptions(organisations?.data?.map(o => o.name));
    };
    if (signup) {
      // only trigger if on signup
      loadOrganisations();
    }
  }, [errorTracker, signup]);

  const getModelDefinition = () => {
    if (login) {
      return Schema.Model({
        email: Schema.Types.StringType().isEmail('Please enter the correct email').isRequired('This field is required'),
        password: Schema.Types.StringType().isRequired('This field is required').minLength(8, 'Min 8 characters'),
      });
    } else if (signup) {
      return Schema.Model({
        email: Schema.Types.StringType().isEmail('Please enter the correct email').isRequired('This field is required'),
        password: Schema.Types.StringType().isRequired('This field is required').minLength(8, 'Min 8 characters'),
        firstName: Schema.Types.StringType().isRequired('This field is required'),
        lastName: Schema.Types.StringType().isRequired('This field is required'),
        organisationName:
          userType && userType === 'landlord' ? Schema.Types.StringType().isRequired('This field is required') : null,
        phone: Schema.Types.StringType().isRequired('This field is required'),
        confirmPassword: Schema.Types.StringType()
          .isRequired('This field is required')
          .addRule((value, data) => {
            if (value !== data.password) {
              return false;
            }
            return true;
          }, 'The passwords are inconsistent twice'),
        adminPassword:
          userType && userType === 'landlord'
            ? Schema.Types.StringType()
                .isRequired('This field is required')
                .addRule(value => {
                  if (value !== process.env.NEXT_PUBLIC_ADMIN_PASSWORD) {
                    return false;
                  }
                  return true;
                }, 'Unauthenticated!')
            : null,
        views:
          userType && userType === 'landlord'
            ? Schema.Types.ArrayType()
                .isRequired('This field is required')
                .addRule(value => {
                  setTimeout(() => {
                    formRef.current && formRef.current.checkForField('views');
                  }, 0); // This has been added to workaround  https://soulrooms.atlassian.net/browse/SPLAT-1275
                  return value && value.length > 0;
                })
            : null,
      });
    } else if (setPassword) {
      return Schema.Model({
        password: Schema.Types.StringType().isRequired('This field is required').minLength(8, 'Min 8 characters'),
        confirmPassword: Schema.Types.StringType()
          .isRequired('This field is required')
          .addRule((value, data) => {
            if (value !== data.password) {
              return false;
            }
            return true;
          }, 'The passwords are inconsistent twice'),
      });
    } else if (forgotPassword) {
      return Schema.Model({
        email: Schema.Types.StringType().isEmail('Please enter the correct email').isRequired('This field is required'),
      });
    }
  };
  // // Used to set the form action that is for popup model
  // const setFormActionHandler = (e, tempFormAction) => {

  //   console.log('Form acion: ' + tempFormAction)
  //   setformAction(tempFormAction);
  // }

  const onClickSubmit = event => {
    event.preventDefault();

    if (!formRef.current.check()) {
      // alert('error');
      return;
    }
    handleSubmit(event);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      onClickSubmit(event);
    }
  };

  return (
    <>
      <Form
        fluid
        ref={formRef}
        formValue={formValue}
        model={getModelDefinition()}
        checkTrigger={'blur'}
        onChange={onChangeFormValue}>
        {/* hide welcome text when isPopup = true */}
        {!isPopup && <StyledSection>{titleText}</StyledSection>}
        {(signup || login || forgotPassword) && (
          <FormGroup>
            <ControlLabel className="leading-8">Email</ControlLabel>
            <FormControl
              name="email"
              size="sm"
              className="email round-border"
              type="email"
              placeholder={'john.doe@gmail.com'}
              onKeyPress={handleKeyPress}
              disabled={setEmail} //Disable the email field when an email is passed as props, used for enforcing email in tenant signup flow
            />
          </FormGroup>
        )}
        {login && (
          <FormGroup>
            <ControlLabel className="leading-8">Password</ControlLabel>
            <PasswordInput onKeyPress={handleKeyPress} name="password" size="sm" className="password round-border " />
            <FlexboxGrid justify="center">
              <FlexboxGrid.Item>
                <Link href="/forgot-password" passHref>
                  {/* If isPopup is true then showing the fogetPassword component else the /forgot-password is shown */}
                  <a
                    onClick={
                      isPopup
                        ? e => {
                            e.preventDefault();
                            setformAction('forgot-password');
                          }
                        : null
                    }>
                    <HelpBlock className="leading-7">Forgot password?</HelpBlock>
                  </a>
                </Link>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </FormGroup>
        )}
        {signup && (
          <FormGroup>
            <ControlLabel className="leading-8">First Name</ControlLabel>
            <FormControl name="firstName" size="sm" className="round-border" placeholder={'John'} />
            <ControlLabel className="leading-8">Last Name</ControlLabel>
            <FormControl name="lastName" size="sm" className="round-border" placeholder={'Doe'} />
            {userType && userType === 'landlord' && (
              <>
                <ControlLabel className="leading-8">Organisation Name</ControlLabel>

                <FormControl
                  name="organisationName"
                  size="sm"
                  className="round-border"
                  placeholder={'Organisation Name'}
                  accepter={AutoComplete}
                  data={organisationNameOptions}
                />
                {organisationNameOptions.indexOf(formValue.organisationName) === -1 && formValue.organisationName && (
                  <>
                    <ControlLabel className="leading-8">Subuser limit</ControlLabel>
                    <FormControl name="userLimit" accepter={InputNumber} size="sm" defaultValue={10} />
                  </>
                )}
              </>
            )}
          </FormGroup>
        )}
        {signup && (
          <FormGroup className="for-phone-ri8">
            <ControlLabel className="leading-8 ">Phone Number</ControlLabel>
            <FormControl
              size="xs"
              name="phone"
              country={'ca'}
              inputProps={{
                name: 'phone',
                className: 'rs-input rs-input-sm phone round-border',
              }}
              // disableDropdown
              accepter={PhoneInput}
            />
          </FormGroup>
        )}
        {(signup || setPassword) && (
          <FormGroup>
            <ControlLabel className="leading-8">Password</ControlLabel>
            <PasswordInput name="password" size="sm" className="password round-border" />
            <ControlLabel className="leading-8"> Confirm Password</ControlLabel>
            <PasswordInput name="confirmPassword" size="sm" className="password round-border" />
            {userType && userType === 'landlord' && (
              <>
                <ControlLabel className="leading-8"> Admin Password</ControlLabel>
                <PasswordInput name="adminPassword" size="sm" className="password round-border" />
                <ControlLabel className="leading-8"> Assign Views</ControlLabel>
                <FormControl
                  accepter={CheckPicker}
                  className={'w-full'}
                  placement="topEnd"
                  searchable={false}
                  name="views"
                  data={[
                    { label: 'Analytics', value: 'dashboard' },
                    { label: 'Convert', value: 'bookings' },
                    { label: 'Engage', value: 'tickets' },
                    { label: 'Income', value: 'income-management' },
                    { label: 'Price', value: 'price' },
                    { label: 'Sage+', value: 'sage+' },
                    { label: 'Application Module', value: 'application-module' },
                    { label: 'Credit Checks', value: 'credit-checks' },
                    { label: 'Price Plus', value: 'price+' },
                    { label: 'Analytics Plus', value: 'analytics-plus' },
                  ]}
                />
              </>
            )}
          </FormGroup>
        )}
        <FormGroup>
          <ButtonToolbar style={{ marginTop: '20px' }}>
            <Button
              size="sm"
              onClick={onClickSubmit}
              className="round-border submit-button login-btn-bg mt-6"
              block
              loading={isLoading}
              appearance="primary">
              {buttonText}
            </Button>
          </ButtonToolbar>
          <FlexboxGrid justify="center">
            <FlexboxGrid.Item>
              {/* For tenant-login page, we hide the link that shows up under the login/signup buttons to go to other pages, as this is handled using page load mechanics */}
              {showLink && (
                <Link href={belowButtonLink} passHref>
                  {/* If the popup flag is true then show all functionality in popup box */}
                  <a
                    onClick={
                      isPopup
                        ? e => {
                            e.preventDefault();
                            //  The popup box is rendered based on belowButtonLink props passed LoginFrom/SignupForm components
                            belowButtonLink === '/login' ? setformAction('login') : setformAction('signup');
                          }
                        : null
                    }>
                    <HelpBlock className="leading-7">{belowButtonText}</HelpBlock>
                  </a>
                </Link>
              )}
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </FormGroup>
      </Form>
    </>
  );
}

FormComponent.prototypes = {
  login: PropTypes.bool,
  signup: PropTypes.bool,
  setPassword: PropTypes.bool,
  forgotPassword: PropTypes.bool,
  buttonText: PropTypes.string,
};

export default FormComponent;
